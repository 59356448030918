import * as React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";
import Button from "../components/blocks/button";


const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 1024px) {
    width: 100vw;
    overflow: hidden;
  }

  @media (min-width: 1200px) {
    height: 100vh;
  }
`;

const HomeContent = styled.div`
  padding: 25px;
  position: relative;
  z-index: 9;

  p {
    position: relative;
  }

  @media (min-width: 1200px) {
    width: 45vw;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 0 0 0;
  }

  /* @media (min-width: 1700px) {
    width: 40vw;
    height: 100%;
  } */

  @media (min-width: 1500px) {
    padding: 20px 50px;
    width: 30vw;
    padding-top: 150px;
  }

  .contentWrapper {
    @media (min-width: 1200px) and (max-width: 1500px) {
      background: #282a36;
      padding: 20px;
      box-shadow: 5px 5px 15px rgba(126, 246, 252, 0.2);
      border-bottom: 10px solid rgba(126, 246, 252, 1);
    }

    @media (min-aspect-ratio: 1501/1269) and (max-aspect-ratio: 131/77) {
      background: #282a36;
      padding: 20px;
      box-shadow: 5px 5px 15px rgba(126, 246, 252, 0.2);
      border-bottom: 10px solid rgba(126, 246, 252, 1);
    }
  }
`;

const HomeGrafics = styled.div`
  .desktopImage {
    display: none;
  }
  .mobileImage {
    height: 50vh;
    width: 100%;
  }
  @media (min-width: 1200px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
    text-align: right;
    overflow: hidden;

    .gatsby-image-wrapper {
      height: 100vh;
      width: 100vw;
    }

    img {
      position: absolute;
      top: 0;
      right: -15%;
      object-fit: contain !important;
      object-position: center right;
      height: 100vh;
      width: auto;
      margin-left: auto !important;
      margin-right: 0 !important;
    }
  }

  @media (min-width: 1200px) {
    img {
      right: -10%;
    }
    .desktopImage {
      display: block;
    }
    .mobileImage {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    img {
      right: -4%;
    }
  }
`;

const IndexTemplatePage = ({ location, data }) => {
  return (
    <Layout path={location.pathname}>
      <GatsbySeo
        title={data.sanityHomepage.seo.title}
        description={data.sanityHomepage.seo.description}
      />
      <HomeWrapper>
        <HomeContent>
          <div className="contentWrapper">
            <h1>{data.sanityHomepage.title}</h1>
            <PortableText value={data.sanityHomepage.content} />
            <Button text="Zum Showroom" url="/showroom"/>
          </div>
        </HomeContent>
        <HomeGrafics>
          <GatsbyImage
            className="desktopImage"
            image={getImage(data.sanityHomepage.image.asset)}
            alt={data.sanityHomepage.image.asset.altText}
            loading="eager"
          />
          <GatsbyImage
            className="mobileImage"
            image={getImage(data.sanityHomepage.mobile_image.asset)}
            alt={data.sanityHomepage.mobile_image.asset.altText}
            loading="eager"
          />
        </HomeGrafics>
      </HomeWrapper>
    </Layout>
  );
};

export const query = graphql`
  query HomePageTemplateQuery {
    sanityHomepage {
      id
      title
      content: _rawContent
      image {
        asset {
          url
          altText
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      mobile_image {
        asset {
          url
          altText
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      seo {
        title
        description
      }
    }
  }
`;

export default IndexTemplatePage;
