import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const BtnWrapper = styled.div`
  position: relative;
  display: inline-block;

  /* .gatsby-image-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;
    transition: all 0.3s ease-out;
    opacity: 0;
    transform: translateX(30px);
  }

  & a:hover + .gatsby-image-wrapper {
    opacity: 1;
    transform: translateX(0);
  } */
`;

const LinkBtn = styled(Link)`
  display: inline-block;
  position: relative;
  z-index: 9;
  width: auto;
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
  font-family: "Boogaloo", sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 25px;
  letter-spacing: 2px;

  .btnArrow {
    transition: all 0.3s ease-out;
    display: inline-block;
  }

  &:hover {
    letter-spacing: 2px;
    color: #7ef6fc;
    text-shadow: 0 2px 10px pink;
  }

  &:hover .btnArrow {
    transform: translateX(10px);
  }
`;

const Button = ({ url, text, blank }) => {
  return (
    <BtnWrapper>
      <LinkBtn to={url} target={blank ? "_blank" : ""}>
        {text} <span className="btnArrow">→</span>
      </LinkBtn>
      {/* <StaticImage
        src="../../images/menuDustBlue.png"
        alt="Active Menu Indicator"
        width={150}
      /> */}
    </BtnWrapper>
  );
};

export default Button;
